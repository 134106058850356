import { useContext, useEffect, useState } from "react";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import { ColumnLayout, Container, Modal, Popover } from "@amzn/awsui-components-react";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { RoleContext } from "./RoleProvider";
import { DefinitionsContext } from "./DefinitionsProvider";
import EffectivenessDefinitionsLayout from "./EffectivenessDefinitionsLayout";

import { paginationLabels } from "../constants";
import { ModalContext } from "./useModal";
import { HealthCheckRowType } from "../types";
import EmptyState from "./EmptyState";
import { getBaseUrl } from "src/utils";
import { useLocalStorage } from "src/utils/use-local-storage";



function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
}

const getRiskColor = (rating: number): string => {
    if (rating > 19) {
        return "red";
    }
    if (rating > 10) {
        return "orange";
    }
    if (rating > 4) {
        return "green";
    }
    return "#B6BEC9";
}

type ContentDisplayItem = {
    id: string;
    visible: boolean;
};

const DEFAULT_PREFERENCES = {
    pageSize: 50,
    contentDisplay: [
        { id: "risk-category", visible: true },
        { id: "risk", visible: true },
        { id: "controls-number", visible: true },
        { id: "percentage", visible: true },
        { id: "effectiveness", visible: true },
        { id: "control-likelihood", visible: true },
        { id: "incident-based-likelihood", visible: true },
        { id: "consequence", visible: true },
        { id: "risk-rating", visible: true },
    ]
};

export default () => {
    const { token } = useContext(RoleContext);
    const { selectedEntity, isShowing, hideModals } = useContext(ModalContext);

    const [healthCheckResults, setHealthCheckResults] = useState<HealthCheckRowType[]>([]);
    const [preferences, setPreferences] = useLocalStorage<{
        pageSize: number,
        contentDisplay: readonly ContentDisplayItem[],
    }>("healthcheck-table-preferences", DEFAULT_PREFERENCES);

    useEffect(() => {
        const fxn = async () => {
            if (selectedEntity.site !== "") {
                const res = await fetch(`${getBaseUrl()}/healthCheck?siteName=${selectedEntity.site}`,
                    { headers: new Headers({ "wowie": token }) });
                if (res.status !== 200) {

                    return;
                }
                const json = await res.json();
                const adjustedHealthCheck = json.healthCheck.map((hcDB: { controls_used: string, controls_count: number }) => {
                    return {
                        ...hcDB,
                        controls_count: hcDB.controls_used + " / " + hcDB.controls_count
                    };
                })
                setHealthCheckResults(adjustedHealthCheck);
            }
        }
        fxn();


    }, [selectedEntity.entityId]);

    const { EffectivenessDefinitions, RiskRatingDefinitions } = useContext(DefinitionsContext);
    const {
        items: itemsFromUseCollection,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(healthCheckResults, {
        filtering: {
            empty: (
                <EmptyState
                    title="No Items Found"
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    action={
                        <Button onClick={() => actions.setFiltering("")}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });
    return (
        <Modal
            onDismiss={() => hideModals()}
            visible={isShowing("healthCheck")}
            size="max"
        >
            <Table
                {...collectionProps}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: ({ selectedItems }) =>
                        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                        } selected`,
                }}
                columnDefinitions={[
                    {
                        id: "risk-category",
                        header: "Risk Category",
                        cell: (e: HealthCheckRowType) => e.risk_category_name,
                        isRowHeader: true,
                        width: 330,
                    },
                    {
                        id: "risk",
                        header: "Risk",
                        cell: (e: HealthCheckRowType) => e.risk_name,
                        width: 400,
                    },
                    {
                        id: "controls-number",
                        header: "Number of Controls",
                        cell: (e: HealthCheckRowType) => e.controls_count,
                        width: 225,
                    },
                    {
                        id: "percentage",
                        header: "%",
                        cell: (e: HealthCheckRowType) => `${Math.round(e.coverage_pct * 100)}%`,
                    },
                    {
                        id: "effectiveness",
                        header: <Popover
                            fixedWidth
                            header="Control Effectiveness Definitions"
                            size="large"
                            position="bottom"
                            content={
                                <EffectivenessDefinitionsLayout />
                            }
                        >Average Effectiveness</Popover>,
                        cell: (e: HealthCheckRowType) => (EffectivenessDefinitions[e.effectiveness]?.name),
                        width: 175,

                    },
                    {
                        id: "control-likelihood",
                        header: <Popover
                            fixedWidth
                            header="Control-based Likelihood Definitions"
                            size="large"
                            content={
                                <ColumnLayout>
                                    <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
                                    <Container>
                                        <Box>1 - <b>Rare</b></Box>
                                        <Box>All of the controls are strong, with no control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors beyond the control of the organisation.</Box></Container>
                                    <Container>
                                        <Box>2 - <b>Unlikely</b></Box>
                                        <Box>The majority of the controls are strong, with few control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors not known to the organisation.</Box></Container>
                                    <Container>
                                        <Box>3 - <b>Possible</b></Box>
                                        <Box>Some controls associated with the risk are weak and/or missing. However, without control improvement there is still no certainty that the risk will eventuate.</Box></Container>
                                    <Container>
                                        <Box>4 - <b>Likely</b></Box>
                                        <Box>The majority of the controls associated with the risk are weak and/or missing. Without control improvement it is more likely than not that the risk will eventuate.</Box></Container>
                                    <Container>
                                        <Box>5 - <b>Almost Certain</b></Box>
                                        <Box>All the controls associated with the risk are extremely weak and/or missing. Without control improvement there is almost no doubt that the risk will eventuate.</Box></Container>
                                </ColumnLayout>
                            }
                        >CBL</Popover>,
                        cell: (e: HealthCheckRowType) => e.cbr_likelihood,
                    },
                    {
                        id: "incident-based-likelihood",
                        header: <Popover
                            fixedWidth
                            header="Incident-based Likelihood Definitions"
                            size="large"
                            content={
                                <ColumnLayout>
                                    <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
                                    <Container>
                                        <Box>1 - <b>Rare</b></Box>
                                        <Box>All of the controls are strong, with no control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors beyond the control of the organisation.</Box></Container>
                                    <Container>
                                        <Box>2 - <b>Unlikely</b></Box>
                                        <Box>The majority of the controls are strong, with few control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors not known to the organisation.</Box></Container>
                                    <Container>
                                        <Box>3 - <b>Possible</b></Box>
                                        <Box>Some controls associated with the risk are weak and/or missing. However, without control improvement there is still no certainty that the risk will eventuate.</Box></Container>
                                    <Container>
                                        <Box>4 - <b>Likely</b></Box>
                                        <Box>The majority of the controls associated with the risk are weak and/or missing. Without control improvement it is more likely than not that the risk will eventuate.</Box></Container>
                                    <Container>
                                        <Box>5 - <b>Almost Certain</b></Box>
                                        <Box>All the controls associated with the risk are extremely weak and/or missing. Without control improvement there is almost no doubt that the risk will eventuate.</Box></Container>
                                </ColumnLayout>
                            }
                        >IBL</Popover>,
                        cell: (e: HealthCheckRowType) => e.ib_likelihood,
                    },
                    {
                        id: "consequence",
                        header: "Consequence",
                        cell: (e: HealthCheckRowType) => (<span>{e.consequence_rating}</span>)
                    },
                    {
                        id: "risk-rating",
                        header: "Risk Rating",
                        cell: (e: HealthCheckRowType) => (<span style={{ color: getRiskColor(e.risk_rating) }}>{Math.round(e.risk_rating)} - {RiskRatingDefinitions[Math.round(e.risk_rating)]}</span>)
                    },
                ]}
                columnDisplay={preferences.contentDisplay}
                enableKeyboardNavigation
                items={itemsFromUseCollection}
                loadingText="Loading healthcheck data"
                wrapLines
                trackBy="risk_name"
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                        filteringPlaceholder="Find a risk or risk category"
                        filteringAriaLabel="Filter risks"
                    />
                }
                header={
                    <Header>
                        Health Check for {selectedEntity.site}
                    </Header>
                }
                pagination={
                    <Pagination {...paginationProps} ariaLabels={paginationLabels} />
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        onConfirm={({ detail }) => {
                            const { pageSize, contentDisplay } = detail;
                            if (typeof pageSize === 'number' && contentDisplay) {
                                setPreferences({ pageSize, contentDisplay });
                            }
                        }}
                        pageSizePreference={{
                            title: "Page Size",
                            options: [
                                { value: 10, label: "10" },
                                { value: 20, label: "20" },
                                { value: 50, label: "50" },
                                { value: 100, label: "100" },
                            ],
                        }}
                        contentDisplayPreference={{
                            options: [
                                {
                                    id: "risk-category",
                                    label: "Category",
                                    alwaysVisible: true,
                                },
                                { id: "risk", label: "Risk", alwaysVisible: true },
                                { id: "controls-number", label: "Number of Controls" },
                                { id: "percentage", label: "%" },
                                { id: "effectiveness", label: "Average Effectiveness" },
                                { id: "control-likelihood", label: "Control-based Risk Likelihood" },
                                { id: "incident-based-likelihood", label: "Incident-based Risk Likelihood" },
                                { id: "consequence", label: "Consequence" },
                                { id: "risk-rating", label: "Risk Rating" },
                            ],
                        }}
                    />
                }
            />
        </Modal>
    );
};
