
import React, {useContext, useState} from "react";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {paginationLabels} from "../../constants";
import {IncidentType} from "../../types";
import { ModalContext } from "../useModal";
import EmptyState from "../EmptyState";
import { prettyDateString } from "src/utils";
import { IncidentsContext } from "./IncidentsProvider";


export type IncidentsTableProps = {
    incidents: IncidentType[]
}

const RawIncidents = () => {
    const { rawIncidents } = useContext(IncidentsContext);
    const [preferences, setPreferences] = useState({ pageSize: 25, visibleContent: ['id', 'availabilityZone', 'state'] });
    const {setSelectedIncidents, showModal} = useContext(ModalContext);

    const { items: incidentItems, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
      rawIncidents,
        {
          filtering: {
            noMatch: (
              <EmptyState
                title="No matches"
                action={
                  <Button onClick={() => actions.setFiltering("")}>
                    Clear filter
                  </Button>
                }
              />
            ),
          },
          pagination: { pageSize: preferences.pageSize  },
          sorting: {},
          selection: {},
        }
      );


  const [
    selectedItems,
    setSelectedItems
  ] = React.useState<IncidentType[]>([]);

  const isButtonDisabled = selectedItems.length === 0;

  return (
    <Table
      {...collectionProps}
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
        setSelectedIncidents(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${
            selectedItems.length === 1 ? "item" : "items"
          } selected`,
       
      }}
      columnDefinitions={[
        {
          id: "date_reported",
          header: "Date reported",
          cell: (e: IncidentType) => prettyDateString(e.date_reported),
          sortingField: "date_reported",
          isRowHeader: true,
          width: 225,
        },
        {
          id: "region",
          header: "Region",
          cell: (e: IncidentType) => e.region,
          sortingField: "region",
          width: 140
        },
        {
          id: "country",
          header: "Country",
          cell: (e: IncidentType) => e.country,
          sortingField: "country",
          width: 200
        },
        { id: "type", header: "Type", cell: (e: IncidentType) => e.type },
        {
          id: "location",
          header: "Location",
          cell: (e: IncidentType) => e.location,
          sortingField: "location"
        },
        {
            id: "title",
            header: "Title",
            cell: (e: IncidentType) => e.title,
            sortingField: "title"
          }
      ]}
      columnDisplay={[
        { id: "date_reported", visible: true },
        { id: "region", visible: true },
        { id: "country", visible: true },
        { id: "type", visible: false },
        { id: "location", visible: true },
        { id: "title", visible: true },
      ]}
      enableKeyboardNavigation
      items={incidentItems}
      loadingText="Loading resources"
      selectionType="single"
      wrapLines
      trackBy="id"
      filter={
        <TextFilter
        {...filterProps}
        filteringPlaceholder="Search by location, title, etc."
        filteringAriaLabel="Filter incidents"
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >
              <ButtonDropdown
              onItemClick={(evt) => {
                if(evt.detail.id === "incident_details"){
                  showModal("incident");
                } else if(evt.detail.id === "workbench"){
                  showModal("workbench");
                } 
                setSelectedItems([]);
              }}
                items={[
                  {
                    text: "View details",
                    id: "incident_details",
                    disabled: isButtonDisabled
                  },
                  {
                    text: "Edit in workbench",
                    id: "workbench",
                    disabled: isButtonDisabled
                  }
                ]}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        ></Header>
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      
    />
  );
}

export default RawIncidents;
