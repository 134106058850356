export const getBetaConfig = (callbackURL: string) => {
    return {
    oauth: {
        domain: 'acra-beta.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: callbackURL,
        redirectSignOut: callbackURL,
        responseType: 'code',
    },

    // Cognito options
    aws_cognito_identity_pool_id: 'us-west-2:a2f055f0-a338-412e-8c77-44cd2da589a7',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_1wkASNjnE',
    aws_user_pools_web_client_id: '43uh5fmicl61urs9hfq8q707dp',
    // AppSync
    aws_appsync_graphqlEndpoint: 'https://25voi5ezsfhwhkwi57rfqdznxq.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    API: {
        graphql_endpoint: 'https://25voi5ezsfhwhkwi57rfqdznxq.appsync-api.us-west-2.amazonaws.com/graphql',
        graphql_headers: (async () => ({ Authorization: "", AuthUser: "" }))
    }
}
};

export const getProdConfig = (callbackURL: string) => {
    return {
        oauth: {
            domain: 'acs-smet.auth.us-west-2.amazoncognito.com',
            scope: ['openid'],
            redirectSignIn: callbackURL,
            redirectSignOut: callbackURL,
            responseType: 'code',
        },
    
        // Cognito options
        aws_cognito_identity_pool_id: 'us-west-2:c543f6c8-2f59-4817-afdb-7a786e1e1b5c',
        aws_cognito_region: 'us-west-2',
        aws_user_pools_id: 'us-west-2_0BINkAk87',
        aws_user_pools_web_client_id: '73us79s2med6su5s4kfm0p9veg',
        //AppSync
        aws_appsync_graphqlEndpoint: 'https://fmf6jufdxrfhpjx3vwov22bdp4.appsync-api.us-west-2.amazonaws.com/graphql',
        aws_appsync_region: 'us-west-2',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        API: {
            graphql_endpoint: 'https://fmf6jufdxrfhpjx3vwov22bdp4.appsync-api.us-west-2.amazonaws.com/graphql',
            graphql_headers: (async () => ({ Authorization: "", AuthUser: "" }))
        }
    };
    
}

export const getAmplifyConfig = (url: string) => {
    if(url.indexOf("https://risk.acs.amazon.dev") === 0){
        return getProdConfig(url);
    }
    return getBetaConfig(url);
} 