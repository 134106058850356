import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Container, Header, Select, Form } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";
import ViewIncident from "./ViewIncident";

import { prettyDateString } from "../../utils";


const CS_ViewEntityModal = () => {
  const { isShowing, hideModals, selectedIncidents } = React.useContext(ModalContext);

  
  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("incident")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => hideModals()}>Cancel</Button>

          </SpaceBetween>
        </Box>
      }
      header={selectedIncidents[0].reference}
    >
      <Container>
        
        <ViewIncident />

      </Container>
    </Modal>
  );
}

export default CS_ViewEntityModal;