import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  AttributeEditor,
  Header,
  Select,
  SelectProps,
  Form,
  FormField,
  Multiselect,
  MultiselectProps,
  Textarea,
  Input,
  RadioGroup

} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { ControlDataContext } from "./ControlsProvider";
import { ControlType, SubControlType } from "src/types";

export type ourOptionType = { name: string; isActive: string, key: string };

const CS_EditControlModal = () => {
  const { isShowing, hideModals } = useContext(ModalContext);
  const { controlCategories, controlOwners, selectedControlID, setSelectedControlID, 
    allControls, subControls: allSubControls, updateControl } = useContext(ControlDataContext);
  const localCopyOfControl = { ...allControls.filter(c => c.id === selectedControlID)[0] };

  const {
    category_type_id: categoryTypeIdStartingValue,
    category_name: categoryNameStartingValue,
    control_name: controlNameStartingValue,
    generalDescription: generalDescriptionStartingValue,
    status: statusStartingValue,
    owner_id: ownerIdStartingValue,
    deter: deterStartingValue,
    detect: detectStartingValue,
    delay: delayStartingValue,
    respond: respondStartingValue,
    recover: recoverStartingValue,
    conditional_use: conditionalUseStartingValue,
    hi_impact: hiImpactStartingValue
  } = localCopyOfControl;

  const [selectedCategoryType, setSelectedCategoryType] = React.useState<SelectProps.Option>({});
  const [selectedControlName, setSelectedControlName] = React.useState(controlNameStartingValue);
  const [textareaGDValue, setTextareaGDValue] = React.useState(generalDescriptionStartingValue);
  const [selectedStatus, setSelectedStatus] = React.useState<SelectProps.Option>({});
  const [selectedCategory, setSelectedCategory] = React.useState<SelectProps.Option>({});
  const [selectedOwner, setSelectedOwner] = React.useState<SelectProps.Option>({});
  const [selectedDepth, setSelectedDepth] = React.useState<MultiselectProps.Options>([]);
  const [selectedConditionalUse, setSelectedConditionalUse] = useState("0");
  const [selectedHiImpact, setSelectedHiImpact] = useState("0");
  const [subControls, setSubControls] = React.useState<ourOptionType[]>([]);

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Offline", value: "offline" },
  ];

  const categoryTypeOptions = controlCategories.map((catType) => {
    return { label: catType.name, value: catType.id.toString() };
  });

  const categoryOptions = [
    { label: "People", value: "2" },
    { label: "Physical", value: "4" },
    { label: "Procedural", value: "1" },
    { label: "Technology", value: "3" },
  ];

  const ownerOptions = controlOwners.map((cOwn) => ({label: cOwn.name, value: cOwn.id.toString()}));

  const depthDefenseOptions = [
    { label: "D1 - Deter", value: "deter" },
    { label: "D2 - Delay", value: "delay" },
    { label: "D3 - Detect", value: "detect" },
    { label: "R1 - Respond", value: "respond" },
    { label: "R2 - Recover", value: "recover" },
  ];

  const [subControlsToAdd, setSubControlsToAdd] = useState<SubControlType[]>([]);
  const [subControlsToRemove, setSubControlsToRemove] = useState<SubControlType[]>([]);

  useEffect(() => {
    setSelectedCategoryType(categoryTypeOptions.filter((e) => parseInt(e.value) == categoryTypeIdStartingValue)[0]);
    setSelectedControlName(controlNameStartingValue);
    setTextareaGDValue(generalDescriptionStartingValue);
    //People, Technology, etc
    setSelectedCategory(categoryOptions.filter((e) => e.label == categoryNameStartingValue)[0]);
    setSelectedStatus(statusOptions.filter((e) => e.value == statusStartingValue)[0]);
    setSelectedOwner(ownerOptions.filter((own) => {
      return parseInt(own.value) === ownerIdStartingValue;
    })[0]);
    const tempDefenseOptions = [];
    if (deterStartingValue === 1) {
      tempDefenseOptions.push({ label: "D1 - Deter", value: "deter" })
    }
    if (delayStartingValue === 1) {
      tempDefenseOptions.push({ label: "D2 - Delay", value: "delay" })
    }
    if (detectStartingValue === 1) {
      tempDefenseOptions.push({ label: "D3 - Detect", value: "detect" })
    }
    if (respondStartingValue === 1) {
      tempDefenseOptions.push({ label: "R1 - Respond", value: "respond" })
    }
    if (recoverStartingValue === 1) {
      tempDefenseOptions.push({ label: "R2 - Recover", value: "recover" })
    }
    setSelectedDepth(tempDefenseOptions);

    const subControlsForThisControlFromProvider = allSubControls.filter((sc) => parseInt(sc.controlID) === selectedControlID);
    const subControlsForThisControl = subControlsForThisControlFromProvider.map((x) => {
      return { key: x.id, name: x.name, isActive: x.isActive };
    });
    setSubControls(subControlsForThisControl);

  }, [selectedControlID]);


  const handleLocalSubControlUpdate = (
    itemIndex: number,
    field: "name" | "isActive",
    value: string | undefined
  ) => {

    const tmpItems = [...subControls];
    tmpItems[itemIndex][field] = value || "";
    setSubControls(tmpItems);
  };

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("controlWorkbench")}
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => hideModals()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(_evt) => {

              const id = localCopyOfControl.id;
              const category_id = selectedCategory.value;
              const control_name = selectedControlName;
              const deter = selectedDepth.some((x: MultiselectProps.Option) => x.value === "deter") ? 1 : 0;
              const delay = selectedDepth.some((x: MultiselectProps.Option) => x.value === "delay") ? 1 : 0;
              const detect = selectedDepth.some((x: MultiselectProps.Option) => x.value === "detect") ? 1 : 0;
              const respond = selectedDepth.some((x: MultiselectProps.Option) => x.value === "respond") ? 1 : 0;
              const recover = selectedDepth.some((x: MultiselectProps.Option) => x.value === "recover") ? 1 : 0;
              const owner_id = selectedOwner.value;
              const category_name = selectedCategory.label!;
              const owner_name = selectedOwner.label;
              const generalDescription = textareaGDValue;
              const status = selectedStatus.value;
              const updatedControl: ControlType = {
                id,
                category_type_id: parseInt(selectedCategoryType.value!),
                category_id: parseInt(category_id!),
                category_name,
                control_name,
                deter,
                detect,
                delay,
                recover,
                respond,
                owner_id: parseInt(selectedOwner.value!),
                owner_name: owner_name!,
                generalDescription,
                status: status!,
                conditional_use: parseInt(selectedConditionalUse),
                hi_impact: parseInt(selectedHiImpact)
              }
              updateControl(updatedControl, subControlsToAdd, subControlsToRemove);
              setSubControls([]);
              setSubControlsToAdd([]);
              setSubControlsToRemove([]);
              hideModals();
              setSelectedControlID(0);

            }}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header="Edit Control entry"
    >
      <Form>
        <FormField label="Category type">
          <Select
            selectedOption={selectedCategoryType}
            onChange={({ detail }) =>
              setSelectedCategoryType(detail.selectedOption)
            }
            options={categoryTypeOptions}
          />
        </FormField>
        <FormField label="Control Name">
          <Input
            value={selectedControlName}
            onChange={({ detail }) => setSelectedControlName(detail.value)}
          />
        </FormField>
        <FormField label="General Description">
          <Textarea
            onChange={({ detail }) => setTextareaGDValue(detail.value)}
            value={textareaGDValue}
            placeholder="Add description of the Control here."
          ></Textarea>
        </FormField>
        <FormField label="Category">
          <Select
            selectedOption={selectedCategory}
            onChange={({ detail }) =>
              setSelectedCategory(detail.selectedOption)
            }
            options={categoryOptions}
          />
        </FormField>
        <FormField label="Owner">
          <Select
            selectedOption={selectedOwner}
            onChange={({ detail }) => setSelectedOwner(detail.selectedOption)}
            options={ownerOptions}
          />
        </FormField>
        <FormField label="Conditional use">
          <RadioGroup items={[
            {
              value: "0",
              label: "No"
            },
            {
              value: "1",
              label: "Yes"
            },
          ]} value={selectedConditionalUse} onChange={(evt) => setSelectedConditionalUse(evt.detail.value)} />
        </FormField>
        <FormField label="High impact">
          <RadioGroup items={[
            {
              value: "0",
              label: "No"
            },
            {
              value: "1",
              label: "Yes"
            },
          ]} value={selectedHiImpact} onChange={(evt) => setSelectedHiImpact(evt.detail.value)} />
        </FormField>
        <FormField label="Depth of Defense">
          <Multiselect
            selectedOptions={selectedDepth}
            onChange={({ detail }) => setSelectedDepth(detail.selectedOptions)}
            options={depthDefenseOptions}
            placeholder="Choose defense levels"
          />
        </FormField>
        {/* TO-DO Figure out the Performance Criteria + Effective Rating layout. */}
        <Header variant="h3">Performance Criteria + Effective Rating:</Header>
        <AttributeEditor
          onAddButtonClick={() => {
            const newGuid = nanoid();
            setSubControls([...subControls, { name: "", isActive: "0", key: newGuid }])
            setSubControlsToAdd([...subControlsToAdd, { controlID: selectedControlID.toString(), name: "", isActive: "0", id: newGuid }])
          }}
          onRemoveButtonClick={(evt) => {
            const { detail: { itemIndex } } = evt;

            const { key, name: nameToRemove, isActive: isRemovedSubControlActive } = subControls[itemIndex];
            const tmpsubControls = [...subControls];
            tmpsubControls.splice(itemIndex, 1);
            setSubControls(tmpsubControls);

            const newSubControlsToAdd = subControlsToAdd.filter((x) => x.id !== key);
            setSubControlsToAdd(newSubControlsToAdd);

            const newSubControlsToRemove = [...subControlsToRemove, {
              id: key,
              name: nameToRemove, isActive: isRemovedSubControlActive, controlID: selectedControlID.toString()
            }];
            setSubControlsToRemove(newSubControlsToRemove);
          }}
          items={subControls}
          addButtonText="Add performance criteria"
          definition={[
            {
              label: "Performance criteria",
              control: (item, index) => (
                <Input
                  value={item?.name || ""}
                  onChange={({ detail }) => {
                    const { key } = item;

                    handleLocalSubControlUpdate(index, "name", detail.value);
                    const newSubControlsToAdd = [...subControlsToAdd];
                    let subControlToUpdate = newSubControlsToAdd.filter((sub) => sub.id === key)[0];
                    subControlToUpdate.name = detail.value;
                    setSubControlsToAdd(newSubControlsToAdd);
                  }}
                  placeholder="Enter performance criteria name"
                />
              ),
            },
            {
              label: "Is active?",
              control: (item, index) => {
                return (
                  <Select
                    selectedOption={{
                      label: parseInt(item?.isActive) === 1 ? "Active" : "Inactive",
                      value: parseInt(item?.isActive) === 1 ? "1" : "0",
                    }}
                    onChange={({ detail }) => {
                      const { key } = item;
                      handleLocalSubControlUpdate(
                        index,
                        "isActive",
                        detail.selectedOption.value
                      );
                      const newSubControlsToAdd = [...subControlsToAdd];
                      let subControlToUpdate = newSubControlsToAdd.filter((sub) => sub.id === key)[0];
                      subControlToUpdate.isActive = detail.selectedOption.value!;
                      setSubControlsToAdd(newSubControlsToAdd);
                    }}
                    options={[
                      { label: "Active", value: "1" },
                      { label: "Inactive", value: "0" },
                    ]}
                  />
                );
              }
            },
          ]}
          empty="No performance criteria is associated with this control."
        />
        <FormField label="Status:">
          <Select
            selectedOption={selectedStatus}
            onChange={({ detail }) => setSelectedStatus(detail.selectedOption)}
            options={statusOptions}
          />
        </FormField>
      </Form>
    </Modal>
  );
};

export default CS_EditControlModal;
