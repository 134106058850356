import { Dispatch, createContext, useContext, useEffect, useReducer, useState } from "react";
import { IncidentCountsFromDB, IncidentLikelihoodSurveyTRType, IncidentLikelihoodSurveyResponse, ReducerAction } from "../types";
import { RoleContext } from "./RoleProvider";
import { getBaseUrl } from "src/utils";
import { ModalContext } from "./useModal";

export type LikelihoodSurveyStateType = {
    assessedLikelihoodsByRisk: IncidentLikelihoodSurveyResponse[];
    incidentCounts: IncidentLikelihoodSurveyTRType[];
    successfullyUpdated: boolean;
}

export type RiskConsequenceContextType = {
    state: LikelihoodSurveyStateType;
    dispatch: Dispatch<ReducerAction>;
    postSurveyAnswers: () => void;
};

function reducer(state: LikelihoodSurveyStateType, action: ReducerAction): LikelihoodSurveyStateType {
    switch (action.Type) {
        case "HYDRATE_COUNTS":
            const emptySurveyQuestions: IncidentLikelihoodSurveyTRType[] = action.Cargo.incidentCounts.map((row: IncidentCountsFromDB) => ({
                entity_id: row.entity_id,
                entity_name: row.name,
                subRiskId: row.sub_risk_id,
                sub_risk_full_name: row.sub_risk_full_name,
                assessed_likelihood: row.rating,
                location_count: row.location_count,
                region_count: row.region_count,
                global_count: row.global_count
            }));

            const defaultSurveyResponses: IncidentLikelihoodSurveyResponse[] = action.Cargo.incidentCounts.map((row: IncidentCountsFromDB) => ({
                entity_id: row.entity_id,
                sub_risk_id: row.sub_risk_id,
                assessed_rating: row.rating
            }));
            return {
                ...state,
                incidentCounts: emptySurveyQuestions,
                assessedLikelihoodsByRisk: defaultSurveyResponses
            };
        case "UPDATE_ASSESSED_LIKELIHOOD":
            const copyOfAssessedLikelihoods = [...state.assessedLikelihoodsByRisk];
            const responseToUpdate = copyOfAssessedLikelihoods.filter((alr) => alr.sub_risk_id === action.Cargo.subRiskId)[0];
            responseToUpdate.assessed_rating = action.Cargo.assessedRating;
            return {
                ...state,
                assessedLikelihoodsByRisk: copyOfAssessedLikelihoods,
                successfullyUpdated: false
            };

        case "UPDATE_SAVE_SUCCESS":
            return {
                ...state,
                successfullyUpdated: action.Cargo
            };
    }
    return state;
}


const initialState = {
    //This is the shape of the data I want to POST
    assessedLikelihoodsByRisk: [{
        sub_risk_id: 0,
        assessed_rating: 0
    }],
    //This is the shape of the data we get to initially fill up the table
    incidentCounts: [],
    successfullyUpdated: false
};

export const LikelihoodSurveyContext = createContext<RiskConsequenceContextType>({
    state: initialState,
    dispatch: () => null,
    postSurveyAnswers: () => null
});

export const LikelihoodSurveyProvider = (props: { children: JSX.Element }) => {
    const { selectedEntity } = useContext(ModalContext);
    const { loggedInUser, token } = useContext(RoleContext);

    useEffect(() => {
        const fxn = async () => {
            if (selectedEntity.site !== "") {
                const res = await fetch(`${getBaseUrl()}/riskLikelihood?entityName=${selectedEntity.site}`,
                    { headers: new Headers({ "wowie": token }) });
                if (res.status !== 200) {

                    return;
                }
                const json = await res.json();
                const correctedRows = json.data?.map((like: { suggested_rating: number }) => ({ ...like, assessed_likelihood: like.suggested_rating }));
                dispatch({ Type: "HYDRATE_COUNTS", Cargo: { incidentCounts: correctedRows } });
            }
        }
        fxn();
    }, [selectedEntity.site]);

    const [state, dispatch] = useReducer(reducer, initialState);

    const postSurveyAnswers = async () => {
        const res = await fetch(`${getBaseUrl()}/riskLikelihood`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: "PUT",
            body: JSON.stringify({
                user: loggedInUser,
                entityId: selectedEntity.entityId,
                surveyResponses: state.assessedLikelihoodsByRisk

            }),
        });

        dispatch({ Type: "UPDATE_SAVE_SUCCESS", Cargo: res.status === 200 });
    }

  



    return <LikelihoodSurveyContext.Provider value={{ state, dispatch, postSurveyAnswers }}>{props.children}</LikelihoodSurveyContext.Provider>
}