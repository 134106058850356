import React, { useContext, useEffect, useState } from 'react';

import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from '../constants';
import HomePage from './HomePage_cloudscape'

import { RoleContext } from './RoleProvider';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';


const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (
        resourcePackPath: string | undefined,
        locale: string,
        bundle: string
    ) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const App = () => {
    const { authenticated } = useContext(RoleContext);

    if (authenticated) {
        return (<MbmProvider {...mbmOptions}>
          
                            <HomePage />
                        
        </MbmProvider>)
    } else {
        return (<div >Loading....</div>)
    }
}




export default App;
