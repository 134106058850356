import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Container, Header, } from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";

import { prettyDateString } from "../utils";


const CS_ViewEntityModal = () => {
  const { isShowing, hideModals, selectedEntity } = React.useContext(ModalContext);

  const {
    aor,
    isActive,
    confidentialOrRestricted,
    consequenceSet,
    site,
    type,
    region,
    country,
    RSM,
    ASM,
    PSEC,
    dateCreated,
    protectiveGroup,
    lastModified,
    state_province,
    city,
    address,
    locationType,
    contextStatement,
    protectiveRequirements
  } = selectedEntity;

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("viewEntity")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => hideModals()}>Close</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Container header={
        <Header variant="h2">Entity Details</Header>
      }>
        <>
          <div className="body">
            <div className="modal-body">
              <div className="heading-with-aside">
                <h2>{site}</h2>

              </div>

              <div className="admin-section">
                <div id="entity-status">
                  Status:
                  <label htmlFor="active">
                    <input
                      type="radio"
                      id="active"
                      name="isActive"
                      checked={isActive === 1}
                      readOnly={true}
                    />
                    Active
                  </label>
                  <label htmlFor="inactive">
                    <input
                      type="radio"
                      id="inactive"
                      name="activity"
                      checked={isActive === 0}
                      readOnly={true}
                    />
                    Inactive
                  </label>
                </div>
              </div>
              <div className="entity-info">
                <div id="entity-type">Entity Type: {type}</div>
              </div>
              <div className="entity-info">
                <div>Protective Group: {protectiveGroup}</div>
              </div>
              <div>
                <div id="entity-status">
                  Confidential or Restricted
                  <label htmlFor="active">
                    <input
                      type="radio"
                      id="active"
                      name="isActive"
                      checked={confidentialOrRestricted === 1}
                      disabled
                    />
                    Yes
                  </label>
                  <label htmlFor="inactive">
                    <input
                      type="radio"
                      id="inactive"
                      name="activity"
                      checked={confidentialOrRestricted === 0}
                      disabled
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="entity-additional-info">
                {/* Location Entity Additional Fields form, should connect to the Entity input item to display, when selected above should set "active" class */}
                <div className="additional-fields active" id="location-entity">
                  <div id="location-info">
                    <h3 className="title">Location</h3>

                    <div className="title">Region: {region} </div>
                    <div className="title">AOR: {aor} </div>
                    <div className="title">Country: {country}</div>
                    <div className="title">State or province: {state_province}</div>
                    <div className="title">City: {city}</div>
                    <div className="title">Address: {address}</div>
                    <div className="title">Site: {site}</div>
                    <div className="title">RSM: {RSM}</div>
                    <div className="title">ASM: {ASM}</div>
                    <div className="title">PSEC: {PSEC}</div>
                    <div className="title">Consequence Set: {consequenceSet}</div>
                    <div className="title">Location type: {locationType}</div>
                    <div className="title">Context statement: {contextStatement}</div>
                    <div className="title">Protective requirements: {protectiveRequirements}</div>
                    <div className="title">
                      Created: {prettyDateString(dateCreated)}
                    </div>
                    <div className="title">
                      Last updated: {prettyDateString(lastModified)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

      </Container>
    </Modal>
  );
}

export default CS_ViewEntityModal;