//Modified from https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/pages/commons/use-local-storage.ts
import { useState } from 'react';
import { load, save } from './localStorage';

export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

  function handleValueChange(newValue: T) {
    setValue(newValue);
    save(key, newValue);
  }

  return [value, handleValueChange] as const;
}