import React, { useContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Alert,
  AppLayout,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
  Link,
  SideNavigation,
  SideNavigationProps,
  Toggle,
} from "@amzn/awsui-components-react/polaris";
import { I18nProvider } from "@amzn/awsui-components-react/polaris/i18n";
import messages from "@amzn/awsui-components-react/polaris/i18n/messages/all.en";
import CS_MetricsPanel from "./CS_MetricsPanel";
import CS_EntityTable from "./CS_EntityTable";
import ControlsTableForWorkbench from "./ControlsTableForWorkbench";
import RiskTableForWorkbench from "./RiskTableForWorkbench";
import TriagedIncidentsTable from "./incidents/TriagedIncidentsTable";
import IncidentsTabsForWorkbench from "./incidents/IncidentTabsForWorkbench";
import { EntityAndManagerContext } from './EntityAndManagerProvider';

import CS_ViewEntityModal from "./CS_ViewEntityModal";
import LikelihoodSurveyModal from "./LikelihoodSurveyModal";
import CS_ViewIncidentModal from "./incidents/CS_ViewIncidentModal";
import ConsequenceTable from "./ConsequenceTableForWorkbench";
import EditIncidentModal from "./incidents/EditIncidentModal";
import EditControlModal from "./EditControlModal";
import ControlsSurveyModal from "./ControlsSurveyModal";
import EditRiskCategoryModal from "./EditRiskCategoryModal";
import EditSubRiskModal from "./EditSubRiskModal";
import CreateControlModal from "./CreateControlModal";
import { RoleContext } from "./RoleProvider";
import HealthCheckModal from "./HealthCheckModal";

const LOCALE = "en";
//Adding this comment in beta.  Beta was branched off of 014d

interface Props {
  isDarkMode: boolean;
  isVisualRefresh: boolean;
  toggleDarkMode: () => void;
  toggleVR: () => void;
}

const PrimaryAppLayout = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [notifications, setNotifications] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const { userIsRiskAdmin } = useContext(RoleContext);
  const { setIsEditing, isLoading, error, entities } = useContext(EntityAndManagerContext);


  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isVR, setIsVR] = useState(true);
  const [isCompact, setIsCompact] = useState(true);

  const toggleMode = () => {
    document.body.classList.toggle('awsui-polaris-dark-mode')
    setIsDarkMode((prevMode) => !prevMode);
  };

  const toggleVR = () => {
    document.body.classList.toggle('awsui-visual-refresh')
    setIsVR((prevVR) => !prevVR);
  };

  const toggleCompact = () => {
    document.body.classList.toggle('awsui-polaris-compact-mode')
    setIsCompact((prevCompact) => !prevCompact);
  };

  const toolsMenu = (
    <HelpPanel header={<Header variant="h2">Overview</Header>}>
      <Toggle onChange={toggleMode} checked={isDarkMode}>Dark Mode</Toggle>
      <Toggle onChange={toggleVR} checked={isVR}>Visual Refresh Toggle</Toggle>
      <Toggle onChange={toggleCompact} checked={isCompact}>Compact Mode Toggle</Toggle>
    </HelpPanel>
  );

  const navOptions: SideNavigationProps.Item[] = [{ type: "link", text: "Primary View", href: "/" }];
  if (userIsRiskAdmin) {
    navOptions.push({ type: "link", text: "Incident Workbench", href: "/incidentWorkbench" });
    navOptions.push({ type: "link", text: "Controls Workbench", href: "/controlsWorkbench" });
    navOptions.push({ type: "link", text: "Risks Workbench", href: "/riskWorkbench" });
    navOptions.push({ type: "link", text: "Consequence Workbench", href: "/consequenceWorkbench" })
  }
  const [showSuccess, setShowSuccess] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');

  const successHandler = (msg: string) => {
    setShowSuccess(true);
    setFlashMessage(msg);
  };

  const resetMessages = () => {
    setShowSuccess(false);
    setFlashMessage('');
  };

  return (
    <>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '',
                        text: "Workbenches",
                        /*logo: { alt: "logo", src: isDarkMode ? light : dark }*/
                      }}
                      items={navOptions}
                    />
                  }
                  notifications={<>
                    {showSuccess && <Flashbar
                      items={[
                        {
                          type: 'success',
                          dismissible: true,
                          content: flashMessage,
                          id: 'message_1',
                          onDismiss: () => {
                            setShowSuccess(false);
                          }
                        },
                      ]}

                    />}

                    {!showSuccess && <Alert
                      statusIconAriaLabel="Info"
                      header={<React.Fragment>Beta Environment</React.Fragment>}
                    >
                      This application is currently under development.
                    </Alert>}
                  </>}
                  tools={toolsMenu}
                  toolsOpen={toolsOpen}
                  onToolsChange={({ detail }) => setToolsOpen(detail.open)}
                  content={<>
                    <CS_ViewEntityModal />

                    <LikelihoodSurveyModal onSuccessfulSave={successHandler} />
                    <CS_ViewIncidentModal />
                    <ControlsSurveyModal />
                    <HealthCheckModal />
                    <ContentLayout
                      header={
                        <Header>
                          SEAV+ Primary View
                        </Header>
                      }
                    >
                      <CS_MetricsPanel />
                      <Container
                        header={
                          <Header variant="h2">
                            Entities Library
                          </Header>
                        }
                      >
                        {/* <div className="contentPlaceholder" /> */}
                        <CS_EntityTable entities={entities} clearMessages={resetMessages} />
                      </Container>


                      <Container
                        header={<Header variant="h2">Incidents Library</Header>} variant="stacked"
                      >
                        <TriagedIncidentsTable showEditBtn={false} />
                      </Container>
                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/incidentWorkbench"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/incidentWorkbench',
                        text: "Workbenches",
                      }}
                      items={navOptions}
                    />
                  }
                  tools={toolsMenu}
                  toolsOpen={toolsOpen}
                  onToolsChange={({ detail }) => setToolsOpen(detail.open)}
                  content={<>
                    <CS_ViewIncidentModal />
                    <EditIncidentModal />

                    <ContentLayout
                      header={
                        <Header variant="h1" info={<Link variant="info">Info</Link>}>
                          Incidents Workbench
                        </Header>
                      }
                    >

                      <IncidentsTabsForWorkbench />


                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/controlsWorkbench"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/controlsWorkbench',
                        text: "Workbenches",
                      }}
                      items={navOptions}
                    />
                  }
                  tools={toolsMenu}
                  toolsOpen={toolsOpen}
                  onToolsChange={({ detail }) => setToolsOpen(detail.open)}
                  content={<>
                    <EditControlModal />
                    <CreateControlModal />

                    <ContentLayout
                      header={
                        <Header variant="h1" info={<Link variant="info">Info</Link>}>
                          Controls Workbench
                        </Header>
                      }
                    >
                      <Container
                        header={<Header variant="h2">Controls Library</Header>} variant="stacked"
                      >
                        <ControlsTableForWorkbench />
                      </Container>

                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/riskWorkbench"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/riskWorkbench',
                        text: "Workbenches",
                      }}
                      items={navOptions}
                    />
                  }
                  tools={toolsMenu}
                  toolsOpen={toolsOpen}
                  onToolsChange={({ detail }) => setToolsOpen(detail.open)}
                  content={<>
                    <EditRiskCategoryModal />
                    <EditSubRiskModal />

                    <ContentLayout
                      header={
                        <Header variant="h1" info={<Link variant="info">Info</Link>}>
                          Risk Workbench
                        </Header>
                      }
                    >
                      <Container
                        header={<Header variant="h2">Risk Library</Header>} variant="stacked"
                      >
                        <RiskTableForWorkbench />
                      </Container>

                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/consequenceWorkbench"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  tools={toolsMenu}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/consequenceWorkbench',
                        text: "Workbenches",
                      }}
                      items={navOptions}
                    />
                  }

                  content={<>

                    <ContentLayout
                      header={
                        <Header variant="h1" info={<Link variant="info">Info</Link>}>
                          Workbench
                        </Header>
                      }
                    >
                      <Container
                        header={<Header variant="h2">Consequences Workbench</Header>} variant="stacked"
                      >
                        <ConsequenceTable />
                      </Container>

                    </ContentLayout>
                  </>}
                />}
            />
          </Routes>
        </BrowserRouter>
      </I18nProvider>
    </>
  );
}

export default PrimaryAppLayout;