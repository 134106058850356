import { Flashbar } from "@amzn/awsui-components-react";
import React from "react";

export type CancelWarningProps = {
  showWarning: boolean,
  warningText: string,
  onDismiss?: () => void
}
const CancelWarning = (props: CancelWarningProps) => {
  const { showWarning, warningText } = props;
  if (!showWarning || warningText.length === 0) {
    return null;
  }
  return <Flashbar
    items={[
      { 
        content: warningText,
        dismissible: true,
        id: "message_1",
        onDismiss: props.onDismiss,
        type: "warning",
      },
    ]}
  />
}

export default CancelWarning;