import React, { useState, createContext } from 'react';
import { EntityType, IncidentType } from 'src/types';
import { emptyIncident, emptyLocation } from 'src/constants';

export type ModalContextType = {
	
	isShowing: (s: string) => boolean;
	hideModals: () => void;
	showModal: (s: string) => void;
	selectedEntity: EntityType;
	selectedIncidents: IncidentType[];
	setSelectedIncidents: (incidents: IncidentType[]) => void;
	setSelectedLocation: (loc: EntityType) => void;

}

const dummy: ModalContextType = {
	isShowing: (_modalId: string) => false,
	hideModals: () => { },
	showModal: (_modalId: string) => { },
	selectedEntity: emptyLocation,
	selectedIncidents: [emptyIncident],
	setSelectedLocation: (location: EntityType) => { },
	setSelectedIncidents: (incident: IncidentType[]) => { },

}



const ModalContext = createContext<ModalContextType>(dummy);

const ModalContextProvider = (props: { children: JSX.Element }) => {
	const [currentlyActiveModal, setCurrentlyActiveModal] = useState("");
	//"editing" means that you're working on an existing entity NOT whether your existing entity is in edit mode or view mode
	
	const [selectedEntity, setSelectedEntity] = useState(emptyLocation);
	const [selectedIncidents, setSelectedIncidents] = useState([emptyIncident]);

	function setSelectedLocation(location: EntityType) {
		setSelectedEntity(location);
	}

	function hideModals() {
		setCurrentlyActiveModal("");
	}

	function isShowing(modalId: string) {
		return currentlyActiveModal === modalId;
	}

	function showModal(modalId: string) {
		setCurrentlyActiveModal(modalId);
	}

	return <ModalContext.Provider value={{
		isShowing,
		hideModals,
		showModal,
		selectedEntity,
		selectedIncidents,
		setSelectedIncidents,
		setSelectedLocation
	}}>{props.children}</ModalContext.Provider>;
}

export { ModalContext, ModalContextProvider }