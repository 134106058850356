import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../useModal";
import { prettyDateString } from "../../utils";
import { RoleContext } from '../RoleProvider';


const ViewIncident = () => {
  const {
    hideModals,
    selectedIncidents,
  } = useContext(ModalContext);


  const {
    date_reported,
    id,
    location,
    notes,
    reference,
    region,
    title,
    type,

  } = selectedIncidents[0];

  
  return (<div className="body">
    <div className="modal-body">
      {/* <div className="heading-with-aside">
        <h2>{reference}</h2>
      </div> */}

      <div className="admin-section">
        <div className="space-above">Title: {title.length > 0 ? title : "(none)"}</div>
        <div className="space-above">Date reported: {date_reported}</div>
        <div className="space-above">Region: {region}</div>
        <div className="space-above">Location: {location}</div>
        <div className="space-above">Type: {type}</div>
        <div className="space-above">Notes: <div className="space-above grow-to-fit">{notes}</div></div>
      </div>
    </div>

    {/* <div className="footer">
      <button onClick={() => hideModals()}>Close Modal</button>
    </div> */}
  </div>

  );
};

export default ViewIncident;
