import React from "react";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";

import AggregatedIncidentsAccordion from "./AggregatedIncidentsAccordion";
import TriagedIncidentsTable from "./TriagedIncidentsTable";
import RawIncidents from "./RawIncidents";
import NAandDupeIncidentsTable from "./NAandDupeIncidentsTable";

const IncidentsTabsForWorkbench = () => {
  return (
    <Tabs
    tabs={[
      {
        label: "Snapshot",
        id: "snapshot",
        content: <AggregatedIncidentsAccordion />
      },
      {
        label: "Raw (Pending Triage)",
        id: "raw",
        content: <RawIncidents />
      },
      {
        label: "Triaged",
        id: "triaged",
        content: <TriagedIncidentsTable showEditBtn={true}/>,
      },
      {
        label: "N/A and Duplicates",
        id: "other",
        content: <NAandDupeIncidentsTable />,
      },
    ]}
    />
  );
}

export default IncidentsTabsForWorkbench;