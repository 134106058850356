import { Dispatch, createContext, useContext, useEffect, useReducer, useState } from "react";
import { NameAndIdType, ConsequenceAssessmentDBType, ConsequenceAssessmentTRType, ReducerAction, LabeledScore } from "src/types";
import {  NullableBoolean, ConsequenceFriendlyNames } from "../constants";
import { RoleContext } from "./RoleProvider";
import { RiskContext } from "./RiskProvider";
import { getBaseUrl } from "src/utils";
export type RiskConsequenceStateType = {
  activeCountry: number;
  riskConsequences: ConsequenceAssessmentTRType[];
  countries: NameAndIdType[];
}

export type RiskConsequenceContextType = {
  maxScoreForRisk: (riskId: number) => LabeledScore;
  updateConsequences: () => void;
  state: RiskConsequenceStateType;
  dispatch: Dispatch<ReducerAction>;
  successfullyUpdated: NullableBoolean;
  setSuccessfullyUpdated: (_val: NullableBoolean) => void;
};

const getConsequenceByRiskId = (consequences: ConsequenceAssessmentTRType[], riskId: number) => {
  const newRows = [...consequences];
  return newRows.filter((rc) => rc.subRiskId === riskId)[0];
}
function Reducer(state: RiskConsequenceStateType, action: ReducerAction) {
  switch (action.Type) {
    case "UPDATE_RISK_CONSEQUENCES": {
      return {
        ...state,
        riskConsequences: action.Cargo.riskConsequences
      };
    }

    case "UPDATE_COUNTRIES": {
      return {
        ...state,
        countries: action.Cargo.countries
      };
    }

    case "SET_ACTIVE_COUNTRY": {
      return {
        ...state,
        activeCountry: action.Cargo.selectedCountry
      };
    }

    case "UPDATE_ASSETS_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.assets = action.Cargo.rating;
      return {
        ...state,
        riskConsequences: newRows
      };
    }

    case "UPDATE_SAFETY_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.safety = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }

    case "UPDATE_BUSINESS_OPS_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.business_ops = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
    case "UPDATE_SECURITY_OPS_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.security_ops = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
    case "UPDATE_REPUTATION_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.reputation = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }

    case "UPDATE_REGULATORY_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.regulatory = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
    case "UPDATE_LEGAL_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.legal = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
    case "UPDATE_FINANCIAL_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.financial = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
    case "UPDATE_DATA_VALUE_FOR": {
      const newRows = [...state.riskConsequences];
      const riskToUpdate = getConsequenceByRiskId(newRows, action.Cargo.riskId)
      riskToUpdate.data = action.Cargo.rating;

      return {
        ...state,
        riskConsequences: newRows
      };
    }
  }




  return state;
}

const initialState = {
  activeCountry: -1,
  countries: [],
  riskConsequences: []
};

export const RiskConsequenceContext = createContext<RiskConsequenceContextType>({
  maxScoreForRisk: (riskId: number) => ({ label: "", score: 0 }),
  state: initialState,
  dispatch: () => null,
  updateConsequences: () => { },
  successfullyUpdated: NullableBoolean.unset,
  setSuccessfullyUpdated: (_val: NullableBoolean) => { }
});

export const RiskConsequenceProvider = (props: { children: JSX.Element }) => {
  const { children } = props;
  const { loggedInUser, token } = useContext(RoleContext);
  const { state: riskContextState } = useContext(RiskContext);
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(NullableBoolean.unset);

  useEffect(() => {
    async function getConsequences(baseUrl: string) {
      if (token.length > 0 && state.activeCountry !== -1) {
        const response = await fetch(`${baseUrl}/consequences?country=${state.activeCountry}&alias=${loggedInUser}`, { headers: new Headers({ "wowie": token }) });
        const { consequencesByRisk } = await response.json();
        const withNames: ConsequenceAssessmentTRType[] = consequencesByRisk.map((rc: ConsequenceAssessmentDBType) => {
          return {
            subRiskName: riskContextState.subRisks.filter((sr) => sr.acs_risk_dim_sk === rc.acs_risk_dim_sk)[0].sub_risk_full_name,
            subRiskId: rc.acs_risk_dim_sk,
            assets: rc.assets,
            safety: rc.safety,
            business_ops: rc.business_ops,
            security_ops: rc.security_ops,
            reputation: rc.reputation,
            regulatory: rc.regulatory,
            legal: rc.legal,
            financial: rc.financial,
            data: rc.data
          }
        });
        dispatch({ Type: "UPDATE_RISK_CONSEQUENCES", Cargo: { riskConsequences: withNames } });
      }
    }
    getConsequences(getBaseUrl());
  }, [state.activeCountry]);

  useEffect(() => {
    async function fxn() {
      if (token.length > 0) {
        const response = await fetch(`${getBaseUrl()}/countries`, { headers: new Headers({ "wowie": token }) });
        const { countries } = await response.json();
        const _countries: NameAndIdType[] = countries.map((c: { id: number, country_name: string }) => ({ id: c.id, name: c.country_name }));
        dispatch({ Type: "UPDATE_COUNTRIES", Cargo: { countries: _countries } });
      }
    }
    fxn();
  }, [token, state.activeCountry]);

  const updateConsequences = async () => {
    const slimRiskConsequences = state.riskConsequences.map((r: ConsequenceAssessmentTRType) => ({
      acs_risk_dim_sk: r.subRiskId,
      assets: r.assets,
      safety: r.safety,
      business_ops: r.business_ops,
      security_ops: r.security_ops,
      reputation: r.reputation,
      regulatory: r.regulatory,
      legal: r.legal,
      financial: r.financial,
      data: r.data
    }))

    const res = await fetch(`${getBaseUrl()}/consequences`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "wowie": token
      },
      method: "PUT",
      body: JSON.stringify({
        user: loggedInUser,
        countryId: state.activeCountry,
        riskConsequences: slimRiskConsequences

      }),
    });

    setSuccessfullyUpdated(res.status === 200 ? NullableBoolean.true : NullableBoolean.false);
  }

  const maxScoreForRisk = (riskId: number) => {
    const consequencesForThisRisk = state.riskConsequences.filter((rc: ConsequenceAssessmentTRType) => rc.subRiskId === riskId)[0];
    let highestScore = 0;
    const keys = Object.keys(consequencesForThisRisk).filter((k) => k !== "acs_risk_dim_sk" && k !== "subRiskName" && k !== "subRiskId" );
    keys.map((key) => {
      if (consequencesForThisRisk[key] > highestScore) {
        highestScore = consequencesForThisRisk[key];
      }
    })
    // now figure out which consequences share this highest score
    const hiScores: LabeledScore[] = []
    keys.map((key) => {
      if (consequencesForThisRisk[key] === highestScore) {
        hiScores.push({ label: ConsequenceFriendlyNames[key as keyof typeof ConsequenceFriendlyNames], score: consequencesForThisRisk[key] })
        highestScore = consequencesForThisRisk[key];
      }
    })
    let label = hiScores.map((h) => `${h.label},`).join("");
    return { score: highestScore, label: label.slice(0, -1) };
  };

  return (
    <RiskConsequenceContext.Provider value={{ dispatch, state, successfullyUpdated, setSuccessfullyUpdated, updateConsequences, maxScoreForRisk }}>
      {children}
    </RiskConsequenceContext.Provider>
  );
};
