declare global {
    interface Window {
        AwsUiConsent: CookieConsent;
    }
}

type ConsentCookie = {
    advertising: boolean;
    essential: boolean;
    functional: boolean;
    performance: boolean;
};

export interface CookieConsent {
    checkForCookieConsent: () => void;
    getConsentCookie: () => ConsentCookie;
}

const hasConsent = () => {
    // this stuff might be a problem for security review.  We might want to check whether this app is subject to GDPR.
    //   if (typeof window.AwsUiConsent === 'undefined') {
    //     return false;
    //   }

    //   const cookieConsent = window.AwsUiConsent.getConsentCookie();
    //   return cookieConsent?.functional ?? false;
};

export const save = (key: string, value: any) => {
    //TODO: check consent?
    localStorage.setItem(key, JSON.stringify(value));
};

export const remove = (key: string) => localStorage.removeItem(key);

export const load = (key: string) => {
    const value = localStorage.getItem(key);
    try {
        return value && JSON.parse(value);
    } catch (e) {
        console.warn(
            `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
        );
        return undefined;
    }
};
