import React, { useContext, useEffect, useState } from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import { Box, ColumnLayout, Container, Icon, Popover } from "@amzn/awsui-components-react";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";

const consideredScore: any = (float: number) => {
  const int = Math.round(float);
  if (int === 0) {
    return (<span style={{ color: "gray" }}>Doesn't exist</span>);
  } else if (int >= 1 && int < 4) {
    return (<span style={{ color: "green" }}>Insignificant</span>);
  } else if (int >= 5 && int < 9) {
    return(<span style={{ color: "green" }}>Low</span>);
  } else if (int >= 10 && int < 15) {
    return(<span style={{ color: "orange" }}>Moderate</span>);
  } else if (int >= 16 && int < 24) {
    return(<span style={{ color: "red" }}>High</span>);
  } else if (int <= 25) {
    return(<span style={{ color: "red" }}>Extreme</span>);
  }
};

const consideredACEScore: any = (float: number) => {
  const int = Math.round(float);
  if (int === 0) {
    return (<span style={{ color: "red" }}>Not Present</span>);
  } else if (int === 1) {
    return (<span style={{ color: "red" }}>Not Effective</span>);
  } else if (int === 2) {
    return(<span style={{ color: "orange" }}>Partially Effective</span>);
  } else if (int === 3) {
    return(<span style={{ color: "green" }}>Mostly Effective</span>);
  } else if (int === 4) {
    return(<span style={{ color: "green" }}>Effective</span>);
  } else {
    return(<span style={{ color: "gray" }}>Not Applicable</span>);
  }
};


export default () => {
  const { entities } = useContext(EntityAndManagerContext);
  const [incidentsLast90, setIncidentsLast90] = useState(0);
  const [aggregatedOverallScore, setAggregatedOverallScore] = useState(0);
  const [aggregatedEffectiveness, setAggregatedEffectiveness] = useState(0);

  useEffect(() => {
    if (entities.length > 0) {
      let incidents = 0;
      let scoreTotal = 0;
      let effectivenessTotal = 0;
      // TODO: these three properties are numbers, but they appear here as strings like "2", so I have this hack here where I coerce the number
      // into a string and then parseInt.  I should figure out why this is needed.
      entities.forEach((entity) => {
        if (entity.i90) {
          incidents += parseInt(entity.i90 + "");
        }
        if(entity.site_risk_avg){
          scoreTotal += parseInt(entity.site_risk_avg + "");
        }
        if(entity.avg_effect){
          effectivenessTotal += parseInt(entity.avg_effect + "");
        }
      });
      setIncidentsLast90(incidents);
      setAggregatedOverallScore(Math.round(scoreTotal/entities.filter((ent) => ent.site_risk_avg !== null).length));
      setAggregatedEffectiveness(Math.round(effectivenessTotal/entities.filter((ent) => ent.avg_effect !== null).length));
    }
  }, [entities.length]);

  return (
    <Container>
      <ColumnLayout columns={4} variant="text-grid" minColumnWidth={170}>
        <Box>
          <Box variant="awsui-key-label" textAlign="center"># of Entities</Box>
          <Box fontSize="display-l" fontWeight="bold" textAlign="center">{entities.length <= 1 ? (<Icon name="status-in-progress" size="large"></Icon>) : (entities.length) }</Box>
        </Box>
        <Box textAlign="center">
        <Popover
             dismissButton={false}
             position="bottom"
             size="small"
             triggerType="text"
             content="The is the Overall Score from your entities in this view."
            >
          <Box variant="awsui-key-label" >
            
            Overall Score (aggregated) <Icon name="status-info"  /></Box></Popover>
            <Box textAlign="center"><Popover
            dismissButton={false}
            position="bottom"
            size="small"
            triggerType="text"
            content={<Box>This score is considered {consideredScore(aggregatedOverallScore)}</Box>} //replace insert value with database call.
          ><Box fontSize="display-l" fontWeight="bold" textAlign="center">{entities.length <= 1 ? (<Icon name="status-in-progress" size="large"></Icon>) : aggregatedOverallScore}</Box></Popover></Box>
        </Box>
        <Box>
          <Box variant="awsui-key-label" textAlign="center"># of incidents (90 days)</Box>
          <Box fontSize="display-l" fontWeight="bold" textAlign="center">{entities.length <= 1 ? (<Icon name="status-in-progress" size="large"></Icon>) :(incidentsLast90)}</Box>
        </Box>
        <Box textAlign="center">
        <Popover
            dismissButton={false}
            position="bottom"
            size="small"
            triggerType="text"
            content="This is the Average Control Effectiveness scores for all entities."
            >
          <Box variant="awsui-key-label" >
            
            ACE Score (aggregated) <Icon name="status-info"  />
          </Box></Popover>
          <Box textAlign="center"><Popover
            dismissButton={false}
            position="bottom"
            size="small"
            triggerType="text"
            content={<Box>This score is considered {consideredACEScore(aggregatedEffectiveness)}</Box>}
          ><Box fontSize="display-l" fontWeight="bold">{entities.length <= 1 ? (<Icon name="status-in-progress" size="large"></Icon>) : aggregatedEffectiveness}</Box></Popover></Box>
        </Box>
      </ColumnLayout>
    </Container>
  );
};