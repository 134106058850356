import React, { useContext } from 'react';
import IncidentTableAccordionRow from './IncidentTableAccordionRow';
import { IncidentsContext } from "./IncidentsProvider";

const AggregatedIncidentsAccordion = () => {

    const { rawIncidentsGroupedByDay, 
        needsReviewIncidentsGroupedByDay, 
        bookmarkedIncidentsGroupedByDay,
        duplicateIncidentsGroupedByDay, 
        nonAttributableIncidentsGroupedByMonth,
        triagedIncidentsGroupedByMonth } = useContext(IncidentsContext)
    return (<div>
        <IncidentTableAccordionRow headerText="Raw (Pending Triage)" incidentGroups={rawIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Pending Review" incidentGroups={needsReviewIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Bookmarked" incidentGroups={bookmarkedIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Duplicate" incidentGroups={duplicateIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Not Attributable (N/A)" incidentGroups={nonAttributableIncidentsGroupedByMonth} />
        <IncidentTableAccordionRow headerText="Triaged" incidentGroups={triagedIncidentsGroupedByMonth} />
    </div>)
}

export default AggregatedIncidentsAccordion;