import {BETA_API_BASE_URL, PROD_API_BASE_URL} from "./constants"

export const prettyDateString = (date: string) => {
    if(date?.includes("T")) {
        return date.split("T")[0];
    } else if (date?.includes(" ")) {
        return date.split(" ")[0];
    }
    return "";
}

export const prettyDateTime = (date: string) => {
    let retVal = "";
    if (date.indexOf("T") > -1) {
        retVal = date.split("T").join(" ");
    }
    const lastColonIndex = retVal.lastIndexOf(":");
    retVal = retVal.substring(0, lastColonIndex);
    return retVal;
}

export const getMonthAndYear = (d : Date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
} 

export function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }
  
export const getBaseUrl = () => {
    const currentUrl = window.location.origin;
    if(currentUrl.indexOf("https://risk.acs.amazon.dev") === 0){
       return PROD_API_BASE_URL;
    }
    return BETA_API_BASE_URL;
}