
import React, { useContext, useState } from "react";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { paginationLabels } from "../../constants";
import { ContentDisplayItem, IncidentType } from "../../types";
import { ModalContext } from "../useModal";
import EmptyState from "../EmptyState";
import { prettyDateString } from "src/utils";
import { IncidentsContext } from "./IncidentsProvider";
import { useLocalStorage } from "src/utils/use-local-storage";

export type IncidentsTableProps = {
  incidents: IncidentType[]
}

const NAandDupeIncidentsTable = () => {
  const { naAndDuplicateIncidents } = useContext(IncidentsContext);

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      {
        id: "date_reported",
        visible: true,
      },
      {
        id: "region",
        visible: true,
      },
      {
        id: "country",
        visible: true,
      },
      {
        id: "location",
        visible: true,
      },
      {
        id: "title",
        visible: true,
      },
      {
        id: "sub_risk_full_name",
        visible: true,
      },
      {
        id: "bookmarked",
        visible: true,
      },
      {
        id: "needs_review",
        visible: true,
      },
    ]
  };

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number,
    contentDisplay: readonly ContentDisplayItem[],
  }>("na-and_dupe-incidents-table-preferences", DEFAULT_PREFERENCES);

  const { setSelectedIncidents, showModal } = useContext(ModalContext);

  const { items: incidentItems, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    naAndDuplicateIncidents,
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            action={
              <Button onClick={() => actions.setFiltering("")}>
                Clear filter
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );
  const [
    selectedItems,
    setSelectedItems
  ] = React.useState<IncidentType[]>([]);

  const isButtonDisabled = selectedItems.length === 0;

  return (
    <Table
      {...collectionProps}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
          } selected`,

      }}
      columnDefinitions={[
        {
          id: "date_reported",
          header: "Date reported",
          cell: (e: IncidentType) => prettyDateString(e.date_reported),
          sortingField: "date_reported",
          isRowHeader: true,
          width: 225,
        },
        {
          id: "region",
          header: "Region",
          cell: (e: IncidentType) => e.region,
          sortingField: "region",
          width: 140
        },
        {
          id: "country",
          header: "Country",
          cell: (e: IncidentType) => e.country,
          sortingField: "country",
          width: 140
        },
        { id: "type", header: "Type", cell: (e: IncidentType) => e.type },
        {
          id: "location",
          header: "Location",
          cell: (e: IncidentType) => e.location,
          sortingField: "location"
        },
        {
          id: "title",
          header: "Title",
          cell: (e: IncidentType) => e.title,
          sortingField: "title"
        },
        {
          id: "sub_risk_full_name",
          header: "Risk",
          cell: (e: IncidentType) => e.sub_risk_full_name,
          sortingField: "sub_risk_full_name",
        },
        {
          id: "bookmarked",
          header: "Bookmarked",
          cell: (e: IncidentType) => e.bookmarked == 1 ? "bookmarked" : "no",
          sortingField: "bookmarked",
        },
        {
          id: "needs_review",
          header: "Needs Review",
          cell: (e: IncidentType) => e.needs_review == 1 ? "yes" : "no",
          sortingField: "needs_review",
        },
      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search by location, title, etc."
          filteringAriaLabel="Filter incidents"
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >
              <ButtonDropdown
                onItemClick={(evt) => {
                  if (evt.detail.id === "incident_details") {
                    showModal("incident");
                  } else if (evt.detail.id === "workbench") {
                    showModal("workbench");
                  }
                  setSelectedItems([]);
                }}
                items={[
                  {
                    text: "View details",
                    id: "incident_details",
                    disabled: isButtonDisabled
                  },
                  {
                    text: "Edit in workbench",
                    id: "workbench",
                    disabled: isButtonDisabled
                  }
                ]}
              >
                Actions
              </ButtonDropdown>
            </SpaceBetween>
          }
        ></Header>
      }
      items={incidentItems}
      loadingText="Loading incidents"
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
        setSelectedIncidents(detail.selectedItems);
      }}
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: preferences.pageSize,
            contentDisplay: preferences.contentDisplay,
          }}
          onConfirm={({ detail }) => {
            const { pageSize, contentDisplay } = detail;
            if (typeof pageSize === "number" && contentDisplay) {
              setPreferences({ pageSize, contentDisplay });
            }
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
            ],
          }}
          contentDisplayPreference={{
            options: [
              {
                id: "date_reported",
                label: "Date reported",
                alwaysVisible: true,
              },
              { id: "region", label: "Region" },
              { id: "country", label: "Country" },
              { id: "location", label: "Location" },
              { id: "title", label: "Title", alwaysVisible: true },
              { id: "display_id", label: "Display Id" },
              { id: "sub_risk_full_name", label: "Risk" },
              { id: "bookmarked", label: "Bookmarked" },
              { id: "needs_review", label: "Needs review" },
            ],
          }}
        />
      }
      selectedItems={selectedItems}
      selectionType="single"
      trackBy="id"
      wrapLines
    />
  );
}

export default NAandDupeIncidentsTable;
