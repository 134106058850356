import Box from "@amzn/awsui-components-react/polaris/box";

function EmptyState({ title, subtitle, action }: any) {
    return (
      <Box data-testid="empty-state-box" margin={{ bottom: "s" }} textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: "s" }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  export default EmptyState;