import React, { useContext } from "react";
import { ColumnLayout, Container, Modal, Popover } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import { DefinitionsContext } from "./DefinitionsProvider";

const EffectivenessDefinitionsLayout = () => {
    const { EffectivenessDefinitions } = useContext(DefinitionsContext);
    const containers = [<Container key="foo1"><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>];
    for (let i = 1; i < 5; i++) {
        const partsOfRating = EffectivenessDefinitions[i];
        const parts = partsOfRating.name.split(" - ");
        containers.push(<Container key={`defCtr-${parts[0]}`}><Box >{parts[0]} - <b>{parts[1]}</b></Box>
            <Box>{EffectivenessDefinitions[i].definition}</Box></Container>)
    }
    return (<ColumnLayout>
        {containers}
    </ColumnLayout>)
};

export default EffectivenessDefinitionsLayout;    