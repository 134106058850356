import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  Select,
  SelectProps,
  Form,
  FormField,
  Multiselect,
  MultiselectProps,
  Textarea,
  Input,
  RadioGroup

} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { ControlDataContext } from "./ControlsProvider";
import { ControlType } from "src/types";

const CreateControlModal = () => {
  const { isShowing, hideModals } = React.useContext(
    ModalContext
  );
  const { controlCategories, createControl, controlOwners } = useContext(ControlDataContext);
  const [selectedControlName, setSelectedControlName] = useState("");
  const [textareaGDValue, setTextareaGDValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<SelectProps.Option>({});
  const [selectedCategory, setSelectedCategory] = useState<SelectProps.Option>({});
  const [selectedOwner, setSelectedOwner] = useState<SelectProps.Option>({});
  const [selectedDepth, setSelectedDepth] = useState<MultiselectProps.Options>([]);
  const [selectedConditionalUse, setSelectedConditionalUse] = useState("0");
  const [selectedHiImpact, setSelectedHiImpact] = useState("0");
  const [selectedCategoryType, setSelectedCategoryType] = useState<SelectProps.Option>({});
  const categoryTypeOptions = controlCategories.map((catType) => {
    return { label: catType.name, value: catType.id.toString() };
  });

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Offline", value: "offline" },
  ];

  const categoryOptions = [
    { label: "People", value: "2" },
    { label: "Physical", value: "4" },
    { label: "Procedural", value: "1" },
    { label: "Technology", value: "3" },
  ];

  const controlOwnerOptions = controlOwners.map((cOwn) => ({label: cOwn.name, value: cOwn.id.toString()}));

  const depthDefenseOptions = [
    { label: "D1 - Deter", value: "deter" },
    { label: "D2 - Delay", value: "delay" },
    { label: "D3 - Detect", value: "detect" },
    { label: "R1 - Respond", value: "respond" },
    { label: "R2 - Recover", value: "recover" },
  ];



  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("createControl")}
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => hideModals()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(_evt) => {

              const id = 0;
              const category_id = selectedCategory.value;
              const control_name = selectedControlName;
              const deter = selectedDepth.some((x: MultiselectProps.Option) => x.value === "deter") ? 1 : 0;
              const delay = selectedDepth.some((x: MultiselectProps.Option) => x.value === "delay") ? 1 : 0;
              const detect = selectedDepth.some((x: MultiselectProps.Option) => x.value === "detect") ? 1 : 0;
              const respond = selectedDepth.some((x: MultiselectProps.Option) => x.value === "respond") ? 1 : 0;
              const recover = selectedDepth.some((x: MultiselectProps.Option) => x.value === "recover") ? 1 : 0;
              const category_name = selectedCategory.label!;
              const owner_name = selectedOwner.label;
              const generalDescription = textareaGDValue;
              const status = selectedStatus.value;
              const newControl: ControlType = {
                id,
                category_type_id: parseInt(selectedCategoryType.value!),
                category_id: parseInt(category_id!),
                category_name,
                control_name,
                deter,
                detect,
                delay,
                recover,
                respond,
                owner_id: parseInt(selectedOwner.value!),
                owner_name: owner_name!,
                generalDescription,
                status: status!,
                conditional_use: parseInt(selectedConditionalUse),
                hi_impact: parseInt(selectedHiImpact)
              }
              createControl(newControl);

              hideModals();

            }}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header="Create a new Control"
    >
      <Form>
        <FormField label="Category type">
          <Select
            selectedOption={selectedCategoryType}
            onChange={({ detail }) =>
              setSelectedCategoryType(detail.selectedOption)
            }
            options={categoryTypeOptions}
          />
        </FormField>
        <FormField label="Control Name">
          <Input
            value={selectedControlName}
            onChange={({ detail }) => setSelectedControlName(detail.value)}
          />
        </FormField>
        <FormField label="General Description">
          <Textarea
            onChange={({ detail }) => setTextareaGDValue(detail.value)}
            value={textareaGDValue}
            placeholder="Add description of the Control here."
          ></Textarea>
        </FormField>
        <FormField label="Category">
          <Select
            selectedOption={selectedCategory}
            onChange={({ detail }) =>
              setSelectedCategory(detail.selectedOption)
            }
            options={categoryOptions}
          />
        </FormField>
        <FormField label="Owner">
          <Select
            selectedOption={selectedOwner}
            onChange={({ detail }) => setSelectedOwner(detail.selectedOption)}
            options={controlOwnerOptions}
          />

        </FormField>
        <FormField label="Conditional use">
          <RadioGroup items={[
            {
              value: "0",
              label: "No"
            },
            {
              value: "1",
              label: "Yes"
            },
          ]} value={selectedConditionalUse} onChange={(evt) => setSelectedConditionalUse(evt.detail.value)} />
        </FormField>
        <FormField label="High impact">
          <RadioGroup items={[
            {
              value: "0",
              label: "No"
            },
            {
              value: "1",
              label: "Yes"
            },
          ]} value={selectedHiImpact} onChange={(evt) => setSelectedHiImpact(evt.detail.value)} />
        </FormField>
        <FormField label="Depth of Defense">
          <Multiselect
            selectedOptions={selectedDepth}
            onChange={({ detail }) => setSelectedDepth(detail.selectedOptions)}
            options={depthDefenseOptions}
            placeholder="Choose defense levels"
          />
        </FormField>

        <FormField label="Status:">
          <Select
            selectedOption={selectedStatus}
            onChange={({ detail }) => setSelectedStatus(detail.selectedOption)}
            options={statusOptions}
          />
        </FormField>
      </Form>
    </Modal>
  );
};

export default CreateControlModal;
